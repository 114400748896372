import dayjs from "dayjs";
import en from "dayjs/locale/en-gb";
import utc from "dayjs/plugin/utc";

dayjs.locale(en);

export const textFieldProps = {
  sx: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(0, 0, 0, 0.23)" },
  },
  inputProps: {
    min: 0,
    max: 99999999.99,
    sx: {
      fontSize: "0.8rem",
    },
  },
};
export const percentageTextFieldProps = {
  ...textFieldProps,
  inputProps: {
    ...textFieldProps.inputProps,
    min: 0,
    max: 100,
  },
};
export const PRESETS = [
  {
    label: "Today",
    value: "today",
    start: dayjs().startOf("d").format("YYYY-MM-DD"),
    end: dayjs().endOf("d").format("YYYY-MM-DD"),
  },
  {
    label: "Yesterday",
    value: "yesterday",
    start: dayjs().startOf("d").subtract(1, "d").format("YYYY-MM-DD"),
    end: dayjs().endOf("d").subtract(1, "d").format("YYYY-MM-DD"),
  },
  {
    label: "Last 3 days",
    value: "last3days",
    start: dayjs().subtract(3, "d").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  },
  {
    label: "Last week",
    value: "lastweek",
    start: dayjs().subtract(1, "w").startOf("w")
      .format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "w").endOf("w")
      .format("YYYY-MM-DD"),
  },
  {
    label: "Last month",
    value: "lastmonth",
    start: dayjs().subtract(1, "M").startOf("M").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "M").endOf("M").format("YYYY-MM-DD"),
  },
  {
    label: "This month",
    value: "thismonth",
    start: dayjs().startOf("M").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "This week",
    value: "thisweek",
    start: dayjs().startOf("w").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "This year",
    value: "thisyear",
    start: dayjs().startOf("y").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
];

export function getValue(value: undefined | null | string | number, defaultValue: string, postfix = "") {
  const result = (postfix ? `${value} ${postfix}` : value);
  return value === null || value === undefined ? defaultValue : result;
}

type NonEmpty<T> = T extends null | undefined | "" ? never : T;

type RemoveEmpty<T extends object> = {
  [K in keyof T as NonEmpty<T[K]> extends never ? never : K]: NonEmpty<T[K]>;
};

export function removeEmpty<T extends object>(obj: T): RemoveEmpty<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== "" && (v.length !== 0))
  ) as RemoveEmpty<T>;
}
export function removeKeys<T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result;
}

type NullableKeys<T, K extends keyof T> = Omit<T, K> & {
  [P in K]: T[P] | null;
};

export function emptyAsNull<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[]
): NullableKeys<T, K> {
  const result = { ...obj } as NullableKeys<T, K>;
  keys.forEach((key) => {
    if (result[key] === "") {
      result[key] = null as T[K];
    }
  });
  return result;
}

emptyAsNull({ a: "", b: 1 }, ["a"]);

export function getFlatObjectDifference<T>(obj1: T, obj2: T) {
  const difference: Partial<T> = {};
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      difference[key] = obj1[key];
    }
  }
  return difference;
}

export function getCurrentDate() {
  return new Date();
}

export function toHumanReadableDateTime(date: Date) {
  const dateOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  } as const;

  return date.toLocaleString("en-GB", dateOptions);
}

export function toHumanReadableDateTimeUTC(date: Date) {
  dayjs.extend(utc);
  return dayjs.utc(date).format("DD/MM/YYYY HH:mm:ss");
}

export function prepareUrlCompany(url: string | null) {
  if (!url) return "";
  return (!url.startsWith("http://") && !url.startsWith("https://")) ? `https://${url}` : url;
}

export function toNumber(value: number | string | null): number | null {
  return value === null || value === "" ? null : Number(value);
}

export const getPrefixPostfix = (inputString: string, keyword: string): { prefix: string; postfix: string } | null => {
  const index = inputString.indexOf(keyword.trim());

  if (index !== -1) {
    const prefix = inputString.slice(0, index).trim();
    const postfix = inputString.slice(index + keyword.length).trim();

    return { prefix, postfix };
  }
  return null;
};
